<template>
  <address class="not-italic">
    <div v-if="!localizeNameOrder" :class="titleClass">
      <slot :full-name="fullName" name="title">{{ fullName }}</slot>
    </div>
    <div>
      <div v-for="line in lines" :key="line" :class="bodyClass">
        {{ line }}
      </div>
    </div>

    <div v-if="withPhone" :class="bodyClass" data-test="phonenumber">
      {{ formattedNumber }}
    </div>
  </address>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { tw } from '@backmarket/utils/string/tw'
import { getPhoneNumberInfos } from '@ds/components/InputPhone'

import { useFormatAddress } from '../../composables/useFormatAddress'
import { type Address } from '../../types/address'
import { getFullName } from '../../utils'

export type AddressProps = {
  address: Address
  compact?: boolean
  /**
   * Whether to order the name according to the local addressformat.
   *
   * By default the name is always the first line, which is not correct
   * for some locales.
   * Added for backwards compatibility, but recommended to turn on.
   */
  localizeNameOrder?: boolean
  withDetails?: boolean
  withPhone?: boolean
}

const props = withDefaults(defineProps<AddressProps>(), {
  compact: false,
  localizeNameOrder: false,
  withDetails: false,
  withPhone: true,
})

const fullName = computed(() => {
  return props.address.firstName || props.address.lastName
    ? getFullName(
        props.address.country,
        props.address.firstName,
        props.address.lastName,
      )
    : ''
})

const lines = computed(() => {
  const formatAddress = useFormatAddress(props.address, {
    includeName: props.localizeNameOrder,
  })

  return props.withDetails ? formatAddress : []
})

const formattedNumber = computed(() => {
  const { country, nationalNumber, internationalNumber } = getPhoneNumberInfos(
    `${props.address.countryDialInCode}${
      props.address.countryDialInCode ? ' ' : ''
    }${props.address.phone}`,
  )

  return country === props.address.country
    ? nationalNumber
    : internationalNumber
})

const titleClass = computed(() => {
  return props.compact ? tw`body-2-bold mb-4` : tw`body-1-bold mb-4`
})

const bodyClass = computed(() => {
  return props.compact ? tw`body-2` : tw`body-1`
})
</script>
