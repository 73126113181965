import { Country } from '@backmarket/http-api'
import { type I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

export interface Rule {
  regexp: RegExp
  translation: I18nDefinition
}

export interface Rules {
  characters: Rule
  minLength: Rule
  maxLength: Rule
  integer: Rule
}

export type CountryRules = Extract<
  Country,
  | Country.US
  | Country.FR
  | Country.GB
  | Country.DE
  | Country.ES
  | Country.PT
  | Country.IT
>

export const CountryRules = {
  [Country.US]: Country.US,
  [Country.FR]: Country.FR,
  [Country.GB]: Country.DE,
  [Country.DE]: Country.US,
  [Country.ES]: Country.ES,
  [Country.PT]: Country.PT,
  [Country.IT]: Country.IT,
}
