export default {
  countryAF: {
    id: 'country_AF',
    defaultMessage: 'AF',
  },
  countryAL: {
    id: 'country_AL',
    defaultMessage: 'AL',
  },
  countryDZ: {
    id: 'country_DZ',
    defaultMessage: 'DZ',
  },
  countryAS: {
    id: 'country_AS',
    defaultMessage: 'AS',
  },
  countryAD: {
    id: 'country_AD',
    defaultMessage: 'AD',
  },
  countryAO: {
    id: 'country_AO',
    defaultMessage: 'AO',
  },
  countryAI: {
    id: 'country_AI',
    defaultMessage: 'AI',
  },
  countryAQ: {
    id: 'country_AQ',
    defaultMessage: 'AQ',
  },
  countryAG: {
    id: 'country_AG',
    defaultMessage: 'AG',
  },
  countryAR: {
    id: 'country_AR',
    defaultMessage: 'AR',
  },
  countryAM: {
    id: 'country_AM',
    defaultMessage: 'AM',
  },
  countryAW: {
    id: 'country_AW',
    defaultMessage: 'AW',
  },
  countryAU: {
    id: 'country_AU',
    defaultMessage: 'AU',
  },
  countryAT: {
    id: 'country_AT',
    defaultMessage: 'AT',
  },
  countryAZ: {
    id: 'country_AZ',
    defaultMessage: 'AZ',
  },
  countryBS: {
    id: 'country_BS',
    defaultMessage: 'BS',
  },
  countryBH: {
    id: 'country_BH',
    defaultMessage: 'BH',
  },
  countryBD: {
    id: 'country_BD',
    defaultMessage: 'BD',
  },
  countryBB: {
    id: 'country_BB',
    defaultMessage: 'BB',
  },
  countryBY: {
    id: 'country_BY',
    defaultMessage: 'BY',
  },
  countryBE: {
    id: 'country_BE',
    defaultMessage: 'BE',
  },
  countryBZ: {
    id: 'country_BZ',
    defaultMessage: 'BZ',
  },
  countryBJ: {
    id: 'country_BJ',
    defaultMessage: 'BJ',
  },
  countryBM: {
    id: 'country_BM',
    defaultMessage: 'BM',
  },
  countryBT: {
    id: 'country_BT',
    defaultMessage: 'BT',
  },
  countryBO: {
    id: 'country_BO',
    defaultMessage: 'BO',
  },
  countryBQ: {
    id: 'country_BQ',
    defaultMessage: 'BQ',
  },
  countryBA: {
    id: 'country_BA',
    defaultMessage: 'BA',
  },
  countryBW: {
    id: 'country_BW',
    defaultMessage: 'BW',
  },
  countryBV: {
    id: 'country_BV',
    defaultMessage: 'BV',
  },
  countryBR: {
    id: 'country_BR',
    defaultMessage: 'BR',
  },
  countryIO: {
    id: 'country_IO',
    defaultMessage: 'IO',
  },
  countryBN: {
    id: 'country_BN',
    defaultMessage: 'BN',
  },
  countryBG: {
    id: 'country_BG',
    defaultMessage: 'BG',
  },
  countryBF: {
    id: 'country_BF',
    defaultMessage: 'BF',
  },
  countryBI: {
    id: 'country_BI',
    defaultMessage: 'BI',
  },
  countryCV: {
    id: 'country_CV',
    defaultMessage: 'CV',
  },
  countryKH: {
    id: 'country_KH',
    defaultMessage: 'KH',
  },
  countryCM: {
    id: 'country_CM',
    defaultMessage: 'CM',
  },
  countryCA: {
    id: 'country_CA',
    defaultMessage: 'CA',
  },
  countryKY: {
    id: 'country_KY',
    defaultMessage: 'KY',
  },
  countryCF: {
    id: 'country_CF',
    defaultMessage: 'CF',
  },
  countryTD: {
    id: 'country_TD',
    defaultMessage: 'TD',
  },
  countryCL: {
    id: 'country_CL',
    defaultMessage: 'CL',
  },
  countryCN: {
    id: 'country_CN',
    defaultMessage: 'CN',
  },
  countryCX: {
    id: 'country_CX',
    defaultMessage: 'CX',
  },
  countryCC: {
    id: 'country_CC',
    defaultMessage: 'CC',
  },
  countryCO: {
    id: 'country_CO',
    defaultMessage: 'CO',
  },
  countryKM: {
    id: 'country_KM',
    defaultMessage: 'KM',
  },
  countryCD: {
    id: 'country_CD',
    defaultMessage: 'CD',
  },
  countryCG: {
    id: 'country_CG',
    defaultMessage: 'CG',
  },
  countryCK: {
    id: 'country_CK',
    defaultMessage: 'CK',
  },
  countryCR: {
    id: 'country_CR',
    defaultMessage: 'CR',
  },
  countryHR: {
    id: 'country_HR',
    defaultMessage: 'HR',
  },
  countryCU: {
    id: 'country_CU',
    defaultMessage: 'CU',
  },
  countryCW: {
    id: 'country_CW',
    defaultMessage: 'CW',
  },
  countryCY: {
    id: 'country_CY',
    defaultMessage: 'CY',
  },
  countryCZ: {
    id: 'country_CZ',
    defaultMessage: 'CZ',
  },
  countryCI: {
    id: 'country_CI',
    defaultMessage: 'CI',
  },
  countryDK: {
    id: 'country_DK',
    defaultMessage: 'DK',
  },
  countryDJ: {
    id: 'country_DJ',
    defaultMessage: 'DJ',
  },
  countryDM: {
    id: 'country_DM',
    defaultMessage: 'DM',
  },
  countryDO: {
    id: 'country_DO',
    defaultMessage: 'DO',
  },
  countryEC: {
    id: 'country_EC',
    defaultMessage: 'EC',
  },
  countryEG: {
    id: 'country_EG',
    defaultMessage: 'EG',
  },
  countrySV: {
    id: 'country_SV',
    defaultMessage: 'SV',
  },
  countryGQ: {
    id: 'country_GQ',
    defaultMessage: 'GQ',
  },
  countryER: {
    id: 'country_ER',
    defaultMessage: 'ER',
  },
  countryEE: {
    id: 'country_EE',
    defaultMessage: 'EE',
  },
  countrySZ: {
    id: 'country_SZ',
    defaultMessage: 'SZ',
  },
  countryET: {
    id: 'country_ET',
    defaultMessage: 'ET',
  },
  countryFK: {
    id: 'country_FK',
    defaultMessage: 'FK',
  },
  countryFO: {
    id: 'country_FO',
    defaultMessage: 'FO',
  },
  countryFJ: {
    id: 'country_FJ',
    defaultMessage: 'FJ',
  },
  countryFI: {
    id: 'country_FI',
    defaultMessage: 'FI',
  },
  countryFR: {
    id: 'country_FR',
    defaultMessage: 'FR',
  },
  countryGF: {
    id: 'country_GF',
    defaultMessage: 'GF',
  },
  countryPF: {
    id: 'country_PF',
    defaultMessage: 'PF',
  },
  countryTF: {
    id: 'country_TF',
    defaultMessage: 'TF',
  },
  countryGA: {
    id: 'country_GA',
    defaultMessage: 'GA',
  },
  countryGM: {
    id: 'country_GM',
    defaultMessage: 'GM',
  },
  countryGE: {
    id: 'country_GE',
    defaultMessage: 'GE',
  },
  countryDE: {
    id: 'country_DE',
    defaultMessage: 'DE',
  },
  countryGH: {
    id: 'country_GH',
    defaultMessage: 'GH',
  },
  countryGI: {
    id: 'country_GI',
    defaultMessage: 'GI',
  },
  countryGR: {
    id: 'country_GR',
    defaultMessage: 'GR',
  },
  countryGL: {
    id: 'country_GL',
    defaultMessage: 'GL',
  },
  countryGD: {
    id: 'country_GD',
    defaultMessage: 'GD',
  },
  countryGP: {
    id: 'country_GP',
    defaultMessage: 'GP',
  },
  countryGU: {
    id: 'country_GU',
    defaultMessage: 'GU',
  },
  countryGT: {
    id: 'country_GT',
    defaultMessage: 'GT',
  },
  countryGG: {
    id: 'country_GG',
    defaultMessage: 'GG',
  },
  countryGN: {
    id: 'country_GN',
    defaultMessage: 'GN',
  },
  countryGW: {
    id: 'country_GW',
    defaultMessage: 'GW',
  },
  countryGY: {
    id: 'country_GY',
    defaultMessage: 'GY',
  },
  countryHT: {
    id: 'country_HT',
    defaultMessage: 'HT',
  },
  countryHM: {
    id: 'country_HM',
    defaultMessage: 'HM',
  },
  countryVA: {
    id: 'country_VA',
    defaultMessage: 'VA',
  },
  countryHN: {
    id: 'country_HN',
    defaultMessage: 'HN',
  },
  countryHK: {
    id: 'country_HK',
    defaultMessage: 'HK',
  },
  countryHU: {
    id: 'country_HU',
    defaultMessage: 'HU',
  },
  countryIS: {
    id: 'country_IS',
    defaultMessage: 'IS',
  },
  countryIN: {
    id: 'country_IN',
    defaultMessage: 'IN',
  },
  countryID: {
    id: 'country_ID',
    defaultMessage: 'ID',
  },
  countryIR: {
    id: 'country_IR',
    defaultMessage: 'IR',
  },
  countryIQ: {
    id: 'country_IQ',
    defaultMessage: 'IQ',
  },
  countryIE: {
    id: 'country_IE',
    defaultMessage: 'IE',
  },
  countryIM: {
    id: 'country_IM',
    defaultMessage: 'IM',
  },
  countryIL: {
    id: 'country_IL',
    defaultMessage: 'IL',
  },
  countryIT: {
    id: 'country_IT',
    defaultMessage: 'IT',
  },
  countryJM: {
    id: 'country_JM',
    defaultMessage: 'JM',
  },
  countryJP: {
    id: 'country_JP',
    defaultMessage: 'JP',
  },
  countryJE: {
    id: 'country_JE',
    defaultMessage: 'JE',
  },
  countryJO: {
    id: 'country_JO',
    defaultMessage: 'JO',
  },
  countryKZ: {
    id: 'country_KZ',
    defaultMessage: 'KZ',
  },
  countryKE: {
    id: 'country_KE',
    defaultMessage: 'KE',
  },
  countryKI: {
    id: 'country_KI',
    defaultMessage: 'KI',
  },
  countryKP: {
    id: 'country_KP',
    defaultMessage: 'KP',
  },
  countryKR: {
    id: 'country_KR',
    defaultMessage: 'KR',
  },
  countryKW: {
    id: 'country_KW',
    defaultMessage: 'KW',
  },
  countryKG: {
    id: 'country_KG',
    defaultMessage: 'KG',
  },
  countryLA: {
    id: 'country_LA',
    defaultMessage: 'LA',
  },
  countryLV: {
    id: 'country_LV',
    defaultMessage: 'LV',
  },
  countryLB: {
    id: 'country_LB',
    defaultMessage: 'LB',
  },
  countryLS: {
    id: 'country_LS',
    defaultMessage: 'LS',
  },
  countryLR: {
    id: 'country_LR',
    defaultMessage: 'LR',
  },
  countryLY: {
    id: 'country_LY',
    defaultMessage: 'LY',
  },
  countryLI: {
    id: 'country_LI',
    defaultMessage: 'LI',
  },
  countryLT: {
    id: 'country_LT',
    defaultMessage: 'LT',
  },
  countryLU: {
    id: 'country_LU',
    defaultMessage: 'LU',
  },
  countryMO: {
    id: 'country_MO',
    defaultMessage: 'MO',
  },
  countryMG: {
    id: 'country_MG',
    defaultMessage: 'MG',
  },
  countryMW: {
    id: 'country_MW',
    defaultMessage: 'MW',
  },
  countryMY: {
    id: 'country_MY',
    defaultMessage: 'MY',
  },
  countryMV: {
    id: 'country_MV',
    defaultMessage: 'MV',
  },
  countryML: {
    id: 'country_ML',
    defaultMessage: 'ML',
  },
  countryMT: {
    id: 'country_MT',
    defaultMessage: 'MT',
  },
  countryMH: {
    id: 'country_MH',
    defaultMessage: 'MH',
  },
  countryMQ: {
    id: 'country_MQ',
    defaultMessage: 'MQ',
  },
  countryMR: {
    id: 'country_MR',
    defaultMessage: 'MR',
  },
  countryMU: {
    id: 'country_MU',
    defaultMessage: 'MU',
  },
  countryYT: {
    id: 'country_YT',
    defaultMessage: 'YT',
  },
  countryMX: {
    id: 'country_MX',
    defaultMessage: 'MX',
  },
  countryFM: {
    id: 'country_FM',
    defaultMessage: 'FM',
  },
  countryMD: {
    id: 'country_MD',
    defaultMessage: 'MD',
  },
  countryMC: {
    id: 'country_MC',
    defaultMessage: 'MC',
  },
  countryMN: {
    id: 'country_MN',
    defaultMessage: 'MN',
  },
  countryME: {
    id: 'country_ME',
    defaultMessage: 'ME',
  },
  countryMS: {
    id: 'country_MS',
    defaultMessage: 'MS',
  },
  countryMA: {
    id: 'country_MA',
    defaultMessage: 'MA',
  },
  countryMZ: {
    id: 'country_MZ',
    defaultMessage: 'MZ',
  },
  countryMM: {
    id: 'country_MM',
    defaultMessage: 'MM',
  },
  countryNA: {
    id: 'country_NA',
    defaultMessage: 'NA',
  },
  countryNR: {
    id: 'country_NR',
    defaultMessage: 'NR',
  },
  countryNP: {
    id: 'country_NP',
    defaultMessage: 'NP',
  },
  countryNL: {
    id: 'country_NL',
    defaultMessage: 'NL',
  },
  countryNC: {
    id: 'country_NC',
    defaultMessage: 'NC',
  },
  countryNZ: {
    id: 'country_NZ',
    defaultMessage: 'NZ',
  },
  countryNI: {
    id: 'country_NI',
    defaultMessage: 'NI',
  },
  countryNE: {
    id: 'country_NE',
    defaultMessage: 'NE',
  },
  countryNG: {
    id: 'country_NG',
    defaultMessage: 'NG',
  },
  countryNU: {
    id: 'country_NU',
    defaultMessage: 'NU',
  },
  countryNF: {
    id: 'country_NF',
    defaultMessage: 'NF',
  },
  countryMP: {
    id: 'country_MP',
    defaultMessage: 'MP',
  },
  countryNO: {
    id: 'country_NO',
    defaultMessage: 'NO',
  },
  countryOM: {
    id: 'country_OM',
    defaultMessage: 'OM',
  },
  countryPK: {
    id: 'country_PK',
    defaultMessage: 'PK',
  },
  countryPW: {
    id: 'country_PW',
    defaultMessage: 'PW',
  },
  countryPS: {
    id: 'country_PS',
    defaultMessage: 'PS',
  },
  countryPA: {
    id: 'country_PA',
    defaultMessage: 'PA',
  },
  countryPG: {
    id: 'country_PG',
    defaultMessage: 'PG',
  },
  countryPY: {
    id: 'country_PY',
    defaultMessage: 'PY',
  },
  countryPE: {
    id: 'country_PE',
    defaultMessage: 'PE',
  },
  countryPH: {
    id: 'country_PH',
    defaultMessage: 'PH',
  },
  countryPN: {
    id: 'country_PN',
    defaultMessage: 'PN',
  },
  countryPL: {
    id: 'country_PL',
    defaultMessage: 'PL',
  },
  countryPT: {
    id: 'country_PT',
    defaultMessage: 'PT',
  },
  countryPR: {
    id: 'country_PR',
    defaultMessage: 'PR',
  },
  countryQA: {
    id: 'country_QA',
    defaultMessage: 'QA',
  },
  countryMK: {
    id: 'country_MK',
    defaultMessage: 'MK',
  },
  countryRO: {
    id: 'country_RO',
    defaultMessage: 'RO',
  },
  countryRU: {
    id: 'country_RU',
    defaultMessage: 'RU',
  },
  countryRW: {
    id: 'country_RW',
    defaultMessage: 'RW',
  },
  countryRE: {
    id: 'country_RE',
    defaultMessage: 'RE',
  },
  countryBL: {
    id: 'country_BL',
    defaultMessage: 'BL',
  },
  countrySH: {
    id: 'country_SH',
    defaultMessage: 'SH',
  },
  countryKN: {
    id: 'country_KN',
    defaultMessage: 'KN',
  },
  countryLC: {
    id: 'country_LC',
    defaultMessage: 'LC',
  },
  countryMF: {
    id: 'country_MF',
    defaultMessage: 'MF',
  },
  countryPM: {
    id: 'country_PM',
    defaultMessage: 'PM',
  },
  countryVC: {
    id: 'country_VC',
    defaultMessage: 'VC',
  },
  countryWS: {
    id: 'country_WS',
    defaultMessage: 'WS',
  },
  countrySM: {
    id: 'country_SM',
    defaultMessage: 'SM',
  },
  countryST: {
    id: 'country_ST',
    defaultMessage: 'ST',
  },
  countrySA: {
    id: 'country_SA',
    defaultMessage: 'SA',
  },
  countrySN: {
    id: 'country_SN',
    defaultMessage: 'SN',
  },
  countryRS: {
    id: 'country_RS',
    defaultMessage: 'RS',
  },
  countrySC: {
    id: 'country_SC',
    defaultMessage: 'SC',
  },
  countrySL: {
    id: 'country_SL',
    defaultMessage: 'SL',
  },
  countrySG: {
    id: 'country_SG',
    defaultMessage: 'SG',
  },
  countrySX: {
    id: 'country_SX',
    defaultMessage: 'SX',
  },
  countrySK: {
    id: 'country_SK',
    defaultMessage: 'SK',
  },
  countrySI: {
    id: 'country_SI',
    defaultMessage: 'SI',
  },
  countrySB: {
    id: 'country_SB',
    defaultMessage: 'SB',
  },
  countrySO: {
    id: 'country_SO',
    defaultMessage: 'SO',
  },
  countryZA: {
    id: 'country_ZA',
    defaultMessage: 'ZA',
  },
  countryGS: {
    id: 'country_GS',
    defaultMessage: 'GS',
  },
  countrySS: {
    id: 'country_SS',
    defaultMessage: 'SS',
  },
  countryES: {
    id: 'country_ES',
    defaultMessage: 'ES',
  },
  countryLK: {
    id: 'country_LK',
    defaultMessage: 'LK',
  },
  countrySD: {
    id: 'country_SD',
    defaultMessage: 'SD',
  },
  countrySR: {
    id: 'country_SR',
    defaultMessage: 'SR',
  },
  countrySJ: {
    id: 'country_SJ',
    defaultMessage: 'SJ',
  },
  countrySE: {
    id: 'country_SE',
    defaultMessage: 'SE',
  },
  countryCH: {
    id: 'country_CH',
    defaultMessage: 'CH',
  },
  countrySY: {
    id: 'country_SY',
    defaultMessage: 'SY',
  },
  countryTW: {
    id: 'country_TW',
    defaultMessage: 'TW',
  },
  countryTJ: {
    id: 'country_TJ',
    defaultMessage: 'TJ',
  },
  countryTZ: {
    id: 'country_TZ',
    defaultMessage: 'TZ',
  },
  countryTH: {
    id: 'country_TH',
    defaultMessage: 'TH',
  },
  countryTL: {
    id: 'country_TL',
    defaultMessage: 'TL',
  },
  countryTG: {
    id: 'country_TG',
    defaultMessage: 'TG',
  },
  countryTK: {
    id: 'country_TK',
    defaultMessage: 'TK',
  },
  countryTO: {
    id: 'country_TO',
    defaultMessage: 'TO',
  },
  countryTT: {
    id: 'country_TT',
    defaultMessage: 'TT',
  },
  countryTN: {
    id: 'country_TN',
    defaultMessage: 'TN',
  },
  countryTR: {
    id: 'country_TR',
    defaultMessage: 'TR',
  },
  countryTM: {
    id: 'country_TM',
    defaultMessage: 'TM',
  },
  countryTC: {
    id: 'country_TC',
    defaultMessage: 'TC',
  },
  countryTV: {
    id: 'country_TV',
    defaultMessage: 'TV',
  },
  countryUG: {
    id: 'country_UG',
    defaultMessage: 'UG',
  },
  countryUA: {
    id: 'country_UA',
    defaultMessage: 'UA',
  },
  countryAE: {
    id: 'country_AE',
    defaultMessage: 'AE',
  },
  countryGB: {
    id: 'country_GB',
    defaultMessage: 'GB',
  },
  countryUM: {
    id: 'country_UM',
    defaultMessage: 'UM',
  },
  countryUS: {
    id: 'country_US',
    defaultMessage: 'US',
  },
  countryUY: {
    id: 'country_UY',
    defaultMessage: 'UY',
  },
  countryUZ: {
    id: 'country_UZ',
    defaultMessage: 'UZ',
  },
  countryVU: {
    id: 'country_VU',
    defaultMessage: 'VU',
  },
  countryVE: {
    id: 'country_VE',
    defaultMessage: 'VE',
  },
  countryVN: {
    id: 'country_VN',
    defaultMessage: 'VN',
  },
  countryVG: {
    id: 'country_VG',
    defaultMessage: 'VG',
  },
  countryVI: {
    id: 'country_VI',
    defaultMessage: 'VI',
  },
  countryWF: {
    id: 'country_WF',
    defaultMessage: 'WF',
  },
  countryEH: {
    id: 'country_EH',
    defaultMessage: 'EH',
  },
  countryYE: {
    id: 'country_YE',
    defaultMessage: 'YE',
  },
  countryZM: {
    id: 'country_ZM',
    defaultMessage: 'ZM',
  },
  countryZW: {
    id: 'country_ZW',
    defaultMessage: 'ZW',
  },
  countryAX: {
    id: 'country_AX',
    defaultMessage: 'AX',
  },
}
