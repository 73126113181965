import { Country } from '@backmarket/http-api'

export type NationalIdCountries = Extract<
  Country,
  Country.ES | Country.IT | Country.PT
>

export const NationalIdCountries = {
  [Country.ES]: Country.ES,
  [Country.IT]: Country.IT,
  [Country.PT]: Country.PT,
}
