import { Country } from '@backmarket/http-api'

import translations from '../translations/fields.translations'

export const FIELD_DEFAULT_MAX_LENGTH = 254

export const FIELD_REPORTED_ERRORS = {
  INVALID: 'invalid',
  UNAUTHORIZED: 'unauthorized',
}

export const ERROR_TRANSLATIONS = {
  postalCode: {
    [FIELD_REPORTED_ERRORS.INVALID]: translations.postalCodeInvalid,
    [FIELD_REPORTED_ERRORS.UNAUTHORIZED]: translations.postalCodeUnauthorized,
  },
}

export const FIELDS_STYLE = {
  [Country.JP]: {
    city: { order: 5, shouldDisplay: true },
    company: { order: 8, shouldDisplay: true },
    country: { order: 3, shouldDisplay: true },
    firstName: { order: 102, shouldDisplay: true },
    firstNamePronunciation: { order: 202, shouldDisplay: true },
    lastName: { order: 101, shouldDisplay: true },
    lastNamePronunciation: { order: 201, shouldDisplay: true },
    name: { order: 1, shouldDisplay: true },
    namePronunciation: { order: 2, shouldDisplay: true },
    nationalId: { order: 11, shouldDisplay: true },
    phone: { order: 10, shouldDisplay: true },
    postalCode: { order: 4, shouldDisplay: false },
    postalCodeAutocomplete: { order: 4, shouldDisplay: true },
    publicCompanyName: { order: 9, shouldDisplay: true },
    stateOrProvince: { order: 5, shouldDisplay: false },
    street: { order: 6, shouldDisplay: true },
    street2: { order: 7, shouldDisplay: true },
    streetAutocomplete: { order: 6, shouldDisplay: false },
  },
  [Country.US]: {
    city: { order: 7, shouldDisplay: true },
    company: { order: 3, shouldDisplay: true },
    country: { order: 9, shouldDisplay: true },
    firstName: { order: 101, shouldDisplay: true },
    firstNamePronunciation: { order: 201, shouldDisplay: false },
    lastName: { order: 102, shouldDisplay: true },
    lastNamePronunciation: { order: 202, shouldDisplay: false },
    name: { order: 1, shouldDisplay: true },
    namePronunciation: { order: 2, shouldDisplay: false },
    nationalId: { order: 11, shouldDisplay: true },
    phone: { order: 10, shouldDisplay: true },
    postalCode: { order: 7, shouldDisplay: true },
    postalCodeAutocomplete: { order: 7, shouldDisplay: false },
    publicCompanyName: { order: 4, shouldDisplay: true },
    stateOrProvince: { order: 8, shouldDisplay: false },
    street: { order: 5, shouldDisplay: false },
    street2: { order: 6, shouldDisplay: true },
    streetAutocomplete: { order: 5, shouldDisplay: true },
  },
  [Country.AU]: {
    city: { order: 5, shouldDisplay: true },
    company: { order: 2, shouldDisplay: true },
    country: { order: 2, shouldDisplay: true },
    firstName: { order: 101, shouldDisplay: true },
    firstNamePronunciation: { order: 201, shouldDisplay: false },
    lastName: { order: 102, shouldDisplay: true },
    lastNamePronunciation: { order: 202, shouldDisplay: false },
    name: { order: 1, shouldDisplay: true },
    namePronunciation: { order: 2, shouldDisplay: false },
    nationalId: { order: 11, shouldDisplay: true },
    phone: { order: 8, shouldDisplay: true },
    postalCode: { order: 6, shouldDisplay: true },
    postalCodeAutocomplete: { order: 6, shouldDisplay: false },
    publicCompanyName: { order: 4, shouldDisplay: true },
    stateOrProvince: { order: 7, shouldDisplay: false },
    street: { order: 3, shouldDisplay: false },
    street2: { order: 4, shouldDisplay: true },
    streetAutocomplete: { order: 3, shouldDisplay: true },
  },
  default: {
    city: { order: 0, shouldDisplay: false },
    company: { order: 3, shouldDisplay: true },
    country: { order: 5, shouldDisplay: true },
    firstName: { order: 101, shouldDisplay: true },
    firstNamePronunciation: { order: 201, shouldDisplay: false },
    lastName: { order: 102, shouldDisplay: true },
    lastNamePronunciation: { order: 202, shouldDisplay: false },
    name: { order: 1, shouldDisplay: true },
    namePronunciation: { order: 2, shouldDisplay: false },
    nationalId: { order: 11, shouldDisplay: true },
    phone: { order: 10, shouldDisplay: true },
    postalCode: { order: 9, shouldDisplay: true },
    postalCodeAutocomplete: { order: 9, shouldDisplay: false },
    publicCompanyName: { order: 4, shouldDisplay: true },
    stateOrProvince: { order: 6, shouldDisplay: true },
    street: { order: 7, shouldDisplay: false },
    street2: { order: 8, shouldDisplay: true },
    streetAutocomplete: { order: 7, shouldDisplay: true },
  },
}

export const FIELDS_TAB_INDEX = {
  [Country.JP]: {
    city: 8,
    company: 11,
    country: 5,
    firstName: 2,
    firstNamePronunciation: 4,
    lastName: 1,
    lastNamePronunciation: 3,
    nationalId: 14,
    phone: 13,
    postalCode: 6,
    postalCodeAutocomplete: 6,
    publicCompanyName: 12,
    stateOrProvince: 7,
    street: 9,
    street2: 10,
    streetAutocomplete: 9,
  },
  [Country.AU]: {
    city: 7,
    company: 3,
    country: 4,
    firstName: 1,
    lastName: 2,
    nationalId: 11,
    phone: 9,
    postalCode: 8,
    postalCodeAutocomplete: 8,
    publicCompanyName: 10,
    stateOrProvince: 7,
    street: 5,
    street2: 6,
    streetAutocomplete: 5,
  },
  [Country.US]: {
    city: 7,
    company: 3,
    country: 9,
    firstName: 1,
    lastName: 2,
    nationalId: 11,
    phone: 10,
    postalCode: 6,
    postalCodeAutocomplete: -1,
    publicCompanyName: 10,
    stateOrProvince: 8,
    street: 3,
    street2: 4,
    streetAutocomplete: 3,
  },
}
