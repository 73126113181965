import { Country } from '@backmarket/http-api'

import { nationalitiesTranslations } from '../translations'

// Waiting for thiS PR to be merged to be up to date with Pastrami, https://github.com/BackMarket/pastrami/pull/22509
// We also want to delete the not used translations

// Based on ISO 3166-1 (2023/10/10)
export const NATIONALITIES = {
  [Country.AF]: nationalitiesTranslations.countryAF,
  [Country.AL]: nationalitiesTranslations.countryAL,
  [Country.DZ]: nationalitiesTranslations.countryDZ,
  [Country.AD]: nationalitiesTranslations.countryAD,
  [Country.AO]: nationalitiesTranslations.countryAO,
  [Country.AG]: nationalitiesTranslations.countryAG,
  [Country.AR]: nationalitiesTranslations.countryAR,
  [Country.AM]: nationalitiesTranslations.countryAM,
  [Country.AU]: nationalitiesTranslations.countryAU,
  [Country.AT]: nationalitiesTranslations.countryAT,
  [Country.AZ]: nationalitiesTranslations.countryAZ,
  [Country.BS]: nationalitiesTranslations.countryBS,
  [Country.BH]: nationalitiesTranslations.countryBH,
  [Country.BD]: nationalitiesTranslations.countryBD,
  [Country.BB]: nationalitiesTranslations.countryBB,
  [Country.BY]: nationalitiesTranslations.countryBY,
  [Country.BE]: nationalitiesTranslations.countryBE,
  [Country.BZ]: nationalitiesTranslations.countryBZ,
  [Country.BJ]: nationalitiesTranslations.countryBJ,
  [Country.BT]: nationalitiesTranslations.countryBT,
  [Country.BO]: nationalitiesTranslations.countryBO,
  [Country.BA]: nationalitiesTranslations.countryBA,
  [Country.BW]: nationalitiesTranslations.countryBW,
  [Country.BR]: nationalitiesTranslations.countryBR,
  [Country.BN]: nationalitiesTranslations.countryBN,
  [Country.BG]: nationalitiesTranslations.countryBG,
  [Country.BF]: nationalitiesTranslations.countryBF,
  [Country.BI]: nationalitiesTranslations.countryBI,
  [Country.CV]: nationalitiesTranslations.countryCV,
  [Country.KH]: nationalitiesTranslations.countryKH,
  [Country.CM]: nationalitiesTranslations.countryCM,
  [Country.CA]: nationalitiesTranslations.countryCA,
  [Country.CF]: nationalitiesTranslations.countryCF,
  [Country.TD]: nationalitiesTranslations.countryTD,
  [Country.CL]: nationalitiesTranslations.countryCL,
  [Country.CN]: nationalitiesTranslations.countryCN,
  [Country.CO]: nationalitiesTranslations.countryCO,
  [Country.KM]: nationalitiesTranslations.countryKM,
  [Country.CG]: nationalitiesTranslations.countryCG,
  [Country.CD]: nationalitiesTranslations.countryCD,
  [Country.CR]: nationalitiesTranslations.countryCR,
  [Country.CI]: nationalitiesTranslations.countryCI,
  [Country.HR]: nationalitiesTranslations.countryHR,
  [Country.CU]: nationalitiesTranslations.countryCU,
  [Country.CY]: nationalitiesTranslations.countryCY,
  [Country.CZ]: nationalitiesTranslations.countryCZ,
  [Country.DK]: nationalitiesTranslations.countryDK,
  [Country.DJ]: nationalitiesTranslations.countryDJ,
  [Country.DM]: nationalitiesTranslations.countryDM,
  [Country.DO]: nationalitiesTranslations.countryDO,
  [Country.EC]: nationalitiesTranslations.countryEC,
  [Country.EG]: nationalitiesTranslations.countryEG,
  [Country.SV]: nationalitiesTranslations.countrySV,
  [Country.GQ]: nationalitiesTranslations.countryGQ,
  [Country.ER]: nationalitiesTranslations.countryER,
  [Country.EE]: nationalitiesTranslations.countryEE,
  [Country.SZ]: nationalitiesTranslations.countrySZ,
  [Country.ET]: nationalitiesTranslations.countryET,
  [Country.FJ]: nationalitiesTranslations.countryFJ,
  [Country.FI]: nationalitiesTranslations.countryFI,
  [Country.FR]: nationalitiesTranslations.countryFR,
  [Country.GA]: nationalitiesTranslations.countryGA,
  [Country.GM]: nationalitiesTranslations.countryGM,
  [Country.GE]: nationalitiesTranslations.countryGE,
  [Country.DE]: nationalitiesTranslations.countryDE,
  [Country.GH]: nationalitiesTranslations.countryGH,
  [Country.GR]: nationalitiesTranslations.countryGR,
  [Country.GD]: nationalitiesTranslations.countryGD,
  [Country.GT]: nationalitiesTranslations.countryGT,
  [Country.GN]: nationalitiesTranslations.countryGN,
  [Country.GW]: nationalitiesTranslations.countryGW,
  [Country.GY]: nationalitiesTranslations.countryGY,
  [Country.HT]: nationalitiesTranslations.countryHT,
  [Country.VA]: nationalitiesTranslations.countryVA,
  [Country.HN]: nationalitiesTranslations.countryHN,
  [Country.HU]: nationalitiesTranslations.countryHU,
  [Country.IS]: nationalitiesTranslations.countryIS,
  [Country.IN]: nationalitiesTranslations.countryIN,
  [Country.ID]: nationalitiesTranslations.countryID,
  [Country.IR]: nationalitiesTranslations.countryIR,
  [Country.IQ]: nationalitiesTranslations.countryIQ,
  [Country.IE]: nationalitiesTranslations.countryIE,
  [Country.IL]: nationalitiesTranslations.countryIL,
  [Country.IT]: nationalitiesTranslations.countryIT,
  [Country.JM]: nationalitiesTranslations.countryJM,
  [Country.JP]: nationalitiesTranslations.countryJP,
  [Country.JO]: nationalitiesTranslations.countryJO,
  [Country.KZ]: nationalitiesTranslations.countryKZ,
  [Country.KE]: nationalitiesTranslations.countryKE,
  [Country.KI]: nationalitiesTranslations.countryKI,
  [Country.KP]: nationalitiesTranslations.countryKP,
  [Country.KR]: nationalitiesTranslations.countryKR,
  [Country.KW]: nationalitiesTranslations.countryKW,
  [Country.KG]: nationalitiesTranslations.countryKG,
  [Country.LA]: nationalitiesTranslations.countryLA,
  [Country.LV]: nationalitiesTranslations.countryLV,
  [Country.LB]: nationalitiesTranslations.countryLB,
  [Country.LS]: nationalitiesTranslations.countryLS,
  [Country.LR]: nationalitiesTranslations.countryLR,
  [Country.LY]: nationalitiesTranslations.countryLY,
  [Country.LI]: nationalitiesTranslations.countryLI,
  [Country.LT]: nationalitiesTranslations.countryLT,
  [Country.LU]: nationalitiesTranslations.countryLU,
  [Country.MG]: nationalitiesTranslations.countryMG,
  [Country.MW]: nationalitiesTranslations.countryMW,
  [Country.MY]: nationalitiesTranslations.countryMY,
  [Country.MV]: nationalitiesTranslations.countryMV,
  [Country.ML]: nationalitiesTranslations.countryML,
  [Country.MT]: nationalitiesTranslations.countryMT,
  [Country.MH]: nationalitiesTranslations.countryMH,
  [Country.MR]: nationalitiesTranslations.countryMR,
  [Country.MU]: nationalitiesTranslations.countryMU,
  [Country.MX]: nationalitiesTranslations.countryMX,
  [Country.FM]: nationalitiesTranslations.countryFM,
  [Country.MD]: nationalitiesTranslations.countryMD,
  [Country.MC]: nationalitiesTranslations.countryMC,
  [Country.MN]: nationalitiesTranslations.countryMN,
  [Country.ME]: nationalitiesTranslations.countryME,
  [Country.MA]: nationalitiesTranslations.countryMA,
  [Country.MZ]: nationalitiesTranslations.countryMZ,
  [Country.MM]: nationalitiesTranslations.countryMM,
  [Country.NA]: nationalitiesTranslations.countryNA,
  [Country.NR]: nationalitiesTranslations.countryNR,
  [Country.NP]: nationalitiesTranslations.countryNP,
  [Country.NL]: nationalitiesTranslations.countryNL,
  [Country.NZ]: nationalitiesTranslations.countryNZ,
  [Country.NI]: nationalitiesTranslations.countryNI,
  [Country.NE]: nationalitiesTranslations.countryNE,
  [Country.NG]: nationalitiesTranslations.countryNG,
  [Country.MK]: nationalitiesTranslations.countryMK,
  [Country.NO]: nationalitiesTranslations.countryNO,
  [Country.OM]: nationalitiesTranslations.countryOM,
  [Country.PK]: nationalitiesTranslations.countryPK,
  [Country.PW]: nationalitiesTranslations.countryPW,
  [Country.PA]: nationalitiesTranslations.countryPA,
  [Country.PG]: nationalitiesTranslations.countryPG,
  [Country.PY]: nationalitiesTranslations.countryPY,
  [Country.PE]: nationalitiesTranslations.countryPE,
  [Country.PH]: nationalitiesTranslations.countryPH,
  [Country.PL]: nationalitiesTranslations.countryPL,
  [Country.PT]: nationalitiesTranslations.countryPT,
  [Country.QA]: nationalitiesTranslations.countryQA,
  [Country.RO]: nationalitiesTranslations.countryRO,
  [Country.RU]: nationalitiesTranslations.countryRU,
  [Country.RW]: nationalitiesTranslations.countryRW,
  [Country.KN]: nationalitiesTranslations.countryKN,
  [Country.LC]: nationalitiesTranslations.countryLC,
  [Country.VC]: nationalitiesTranslations.countryVC,
  [Country.WS]: nationalitiesTranslations.countryWS,
  [Country.SM]: nationalitiesTranslations.countrySM,
  [Country.ST]: nationalitiesTranslations.countryST,
  [Country.SA]: nationalitiesTranslations.countrySA,
  [Country.SN]: nationalitiesTranslations.countrySN,
  [Country.RS]: nationalitiesTranslations.countryRS,
  [Country.SC]: nationalitiesTranslations.countrySC,
  [Country.SL]: nationalitiesTranslations.countrySL,
  [Country.SG]: nationalitiesTranslations.countrySG,
  [Country.SK]: nationalitiesTranslations.countrySK,
  [Country.SI]: nationalitiesTranslations.countrySI,
  [Country.SB]: nationalitiesTranslations.countrySB,
  [Country.SO]: nationalitiesTranslations.countrySO,
  [Country.ZA]: nationalitiesTranslations.countryZA,
  [Country.SS]: nationalitiesTranslations.countrySS,
  [Country.ES]: nationalitiesTranslations.countryES,
  [Country.LK]: nationalitiesTranslations.countryLK,
  [Country.SD]: nationalitiesTranslations.countrySD,
  [Country.SR]: nationalitiesTranslations.countrySR,
  [Country.SE]: nationalitiesTranslations.countrySE,
  [Country.CH]: nationalitiesTranslations.countryCH,
  [Country.SY]: nationalitiesTranslations.countrySY,
  [Country.TZ]: nationalitiesTranslations.countryTZ,
  [Country.TH]: nationalitiesTranslations.countryTH,
  [Country.TL]: nationalitiesTranslations.countryTL,
  [Country.TG]: nationalitiesTranslations.countryTG,
  [Country.TO]: nationalitiesTranslations.countryTO,
  [Country.TT]: nationalitiesTranslations.countryTT,
  [Country.TN]: nationalitiesTranslations.countryTN,
  [Country.TR]: nationalitiesTranslations.countryTR,
  [Country.TM]: nationalitiesTranslations.countryTM,
  [Country.TV]: nationalitiesTranslations.countryTV,
  [Country.UG]: nationalitiesTranslations.countryUG,
  [Country.UA]: nationalitiesTranslations.countryUA,
  [Country.AE]: nationalitiesTranslations.countryAE,
  [Country.GB]: nationalitiesTranslations.countryGB,
  [Country.US]: nationalitiesTranslations.countryUS,
  [Country.UY]: nationalitiesTranslations.countryUY,
  [Country.UZ]: nationalitiesTranslations.countryUZ,
  [Country.VU]: nationalitiesTranslations.countryVU,
  [Country.VE]: nationalitiesTranslations.countryVE,
  [Country.VN]: nationalitiesTranslations.countryVN,
  [Country.YE]: nationalitiesTranslations.countryYE,
  [Country.ZM]: nationalitiesTranslations.countryZM,
  [Country.ZW]: nationalitiesTranslations.countryZW,
}
