import { Country } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import {
  type Address as LocalizedAddress,
  formatAddress as localizedFormatAddress,
} from 'localized-address-format'

import type { Address } from '../types'
import { getFullName } from '../utils/name'

const isNotEmpty = (value: unknown) => !isEmpty(value)

export function useFormatAddress(
  address: Address,
  { includeCompany = true, includeCountry = true, includeName = false } = {},
) {
  const fullName =
    includeName && address.country
      ? getFullName(address.country, address.firstName, address.lastName)
      : ''

  // context: https://github.com/google/libaddressinput/issues/177#issuecomment-497741847
  // locality-field is not used for JP. Hence we need to add `city` back.
  const addressLines =
    address.country === Country.JP
      ? [`${address.city}${address.street}`.trim(), address.street2]
      : [address.street, address.street2]

  const localizedAddress: LocalizedAddress = {
    addressLines: addressLines.filter(isNotEmpty) as string[],
    locality: address.city,
    postalCode: address.postalCode,
    administrativeArea: address.stateOrProvince,
    postalCountry: address.country,
    ...insertIf(includeCompany && isNotEmpty(address.company), {
      organization: address.company,
    }),
    ...insertIf(includeName && isNotEmpty(fullName), {
      name: fullName,
    }),
  }
  const lines = localizedFormatAddress(localizedAddress).filter(isNotEmpty)

  // Full name prononciation
  if (address.firstNamePronunciation && address.lastNamePronunciation) {
    const fullNamePronunciation = getFullName(
      address.country,
      address.firstNamePronunciation,
      address.lastNamePronunciation,
    )
    const fullNameLineIndex = lines.findIndex((value) =>
      value.includes(fullName),
    )
    if (fullNamePronunciation && fullNameLineIndex !== -1) {
      lines.splice(fullNameLineIndex + 1, 0, fullNamePronunciation)
    }
  }

  // Country
  if (includeCountry && address.country) {
    const i18n = useI18n()
    if (address.country === 'JP') {
      lines.unshift(i18n.country(address.country))
    } else {
      lines.push(i18n.country(address.country))
    }
  }

  return lines
}
